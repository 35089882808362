import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import removeMarkdown from 'remove-markdown'
import styled from 'styled-components'
import tw, { css } from 'twin.macro'

import { Article } from '@common/components'
import { MainLayout, NewMainLayout, PaginationLayout } from '@common/layouts'

export const query = graphql`
  query ArticlesByTagQuery($skip: Int!, $limit: Int!, $id: Int) {
    allArticle(
      filter: {
        tags: { elemMatch: { id: { eq: $id } } }
      }
      sort: { fields: [publishedAt], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          slug
          content
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

const StyledMainLayout = styled(MainLayout)`
  ${tw`mx-auto max-w-screen-xl`}
`

const StyledNewMainLayout = styled(NewMainLayout)`
  ${tw`mx-auto max-w-screen-xl`}
`

const ListWrapper = styled.div`
  ${tw`flex-1 grid grid-cols-1 gap-16 my-8 mx-8`}
  ${tw`md:(grid-cols-2 my-12)`}
  ${tw`lg:(grid-cols-3)`}
`

const ImageWrapper = styled.div`
  ${tw`relative w-full mb-4`}
`

const Tags = styled(Article.Tags)`
  ${tw`absolute top-0 right-0 justify-end text-white my-4 mx-2 opacity-75`}
  ${css`
    font-size: 11px;
    li {
      ${tw`border-gray-100`}
    }
  `}
`

const ArticlesByPage = (props) => {
  const Layout = props.pageContext.country === 'US' ? StyledNewMainLayout : StyledMainLayout;

  const articles = props.data.allArticle.edges;
  const { currentPage, numPages, slug } = props.pageContext;
  const tagName = props.pageContext.name;

  let SEODescription;
  if (articles?.length > 0)
    SEODescription = articles.slice(0, 5).map((item) => item.node.title).join(' ').substring(0, 150);

  return (
    <PaginationLayout title={`Learn About ${tagName}`} description={SEODescription} path={`tag/${slug}`} currentPage={currentPage} numPages={numPages} Layout={Layout}>
      <ListWrapper>
        {articles.map(({ node: article }) => (
          <Link
            to={`/articles/${article.slug}`}
            title={`Article: ${article.title}`}
            tw="overflow-hidden cursor-pointer"
            key={article.id}
          >
            <article>
              {article.image && (
                <ImageWrapper>
                  <Img
                    tw="h-full w-full"
                    fluid={{
                      ...article.image.childImageSharp.fluid,
                      aspectRatio: 4 / 3,
                    }}
                    alt={article.title}
                  />
                  <Tags tags={article.tags} maxItems={3} />
                </ImageWrapper>
              )}

              <h3 tw="text-xs text-gray-800 mb-2">{article.createdAt}</h3>
              <h2 tw="text-xl text-gray-900 font-bold tracking-tight leading-6 mb-2">
                {article.title}
              </h2>
              <p tw="text-sm text-gray-800 leading-6">
                {removeMarkdown(article.content).replace(
                  /^(.{80}[^\s]*).*/s,
                  '$1...'
                )}
              </p>
            </article>
          </Link>
        ))}
      </ListWrapper>
    </PaginationLayout>
  )
}

export default ArticlesByPage
